import * as moment from "moment";
import { DateTime } from "luxon";

export default class DateUtil {
  static getToLocaleDate( data, time = false ) {
    return moment( data ).format( time ? 'DD/MM/YYYY,  HH:mm' : 'DD/MM/YYYY' );
  }

  static getToLocaleTime( data ) {
    const time = new Date( data );
    const italianTimeFormatter = new Intl.DateTimeFormat( 'it-IT', {
      timeZone: 'Europe/Rome',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // Use 24-hour format
    } );

    const formattedStartTime = italianTimeFormatter.format( time );
    return formattedStartTime;
  }

  static getToLocaleTimeWithoutSecond( data ) {
    const time = new Date( data );
    const italianTimeFormatter = new Intl.DateTimeFormat( 'it-IT', {
      timeZone: 'Europe/Rome',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false, // Use 24-hour format
    } );

    const formattedStartTime = italianTimeFormatter.format( time );
    return formattedStartTime;

  }


  static getItalianTimeFromUTC(utcTime) {
    // Parse the UTC time in 'yyyy-MM-dd HH:mm' format
    const utcDateTime = DateTime.fromFormat(utcTime, 'yyyy-MM-dd HH:mm', {
      zone: 'utc',
    });

    // Convert to the Italian time zone
    const italianDateTime = utcDateTime.setZone('Europe/Rome');
    // Format as 'HH:mm' in the Italian time zone
    return italianDateTime.toFormat('HH:mm:ss');
  }


  static getUTCTimeFromItalian( startTime ) {
    // Get the current date in YYYY-MM-DD format and concatenate it with the given startTime
    const dateTimeString = moment().format( 'YYYY-MM-DD' ) + ' ' + startTime;

    // Parse the dateTimeString in 'YYYY-MM-DD HH:mm' format
    const italianDateTime = DateTime.fromFormat( dateTimeString, 'yyyy-MM-dd HH:mm', {
      zone: 'Europe/Rome',
    } );

    // Convert to UTC
    const utcDateTime = italianDateTime.toUTC();
    // Format as 'yyyy-MM-dd HH:mm' in UTC
    return utcDateTime.toFormat( 'HH:mm' );
  }

  static getUTCDateFromItalian( date ) {
    // Get the current date in YYYY-MM-DD format and concatenate it with the given startTime
    const dateTimeString = moment(new Date(date)).format( 'YYYY-MM-DD' );

    // Parse the dateTimeString in 'YYYY-MM-DD HH:mm' format
    const italianDateDate = DateTime.fromFormat( dateTimeString, 'yyyy-MM-dd', {
      zone: 'Europe/Rome',
    } );

    // Convert to UTC
    const utcDateDate = italianDateDate.toUTC();
    // Format as 'yyyy-MM-dd HH:mm' in UTC
    return utcDateDate.toFormat( 'yyyy-MM-dd' );
  }
}
