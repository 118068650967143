
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { DataEmitterService } from "@/services/DataEmiterService";
import Editor from '@tinymce/tinymce-vue'
import StringUtil from '@/utils/StringUtil'
import TranslationUtil from '@/utils/TranslationUtil'
import TranslationWrapper from '@/layouts/components/TranslationWrapper.vue'
import DateUtil from "@/utils/DateUtil";
import * as moment from "moment";

export default {

  components: {
    Multiselect,
    TranslationWrapper,
    'editor': Editor
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      translations: {},
      facilitiesSnippet: snippets ?? {},
      facilitiesList: [],
      paginatedFacilitiesList: [],
      paginatedSearchedFacilitiesList: [],
      facilitiesPaginatedList: [],
      rolesList: [],
      facilitiesListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      newEventName: '',
      addOrEditSpace: '',
      currentSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        facilityId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: false,
        isInvitational: false,
        isEventCapable: false,
        photos: [],
        services: [],
        photoIds: [],
      },
      defaultSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        facilityId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: false,
        isInvitational: false,
        isEventCapable: false,
        photos: [],
        services: [],
        photoIds: [],
      },
      dialog: false,
      spaceDialog: false,
      eventDialog: false,
      editEventDialog: false,
      addEventsDialog: false,
      dialogDelete: false,
      dialogDeleteSpace: false,
      dialogDeletePhoto: false,
      dialogCarousel: false,
      deleteEventDialog: false,
      dialogUpgrade: false,
      submitted: false,
      globalError: true,
      eventsSubmitted: false,
      spaceSubmitted: false,
      mobileNavBar: false,
      facilityId: this.$route.params.id,
      editedIndex: -1,
      toDeleteSpace: {},
      workDays: [],
      editedItem: {
        facility: {
          id: '',
          lockId: '',
          name: '',
          description: '',
          shortDescription: '',
          address: '',
          qrCode: '',
          workDays: [
            {
              workday: 0,
              startTime: '',
              endTime: ''
            }, {
              workday: 1,
              startTime: '',
              endTime: ''
            }, {
              workday: 2,
              startTime: '',
              endTime: ''
            }, {
              workday: 3,
              startTime: '',
              endTime: ''
            }, {
              workday: 4,
              startTime: '',
              endTime: ''
            }, {
              workday: 5,
              startTime: '',
              endTime: ''
            }, {
              workday: 6,
              startTime: '',
              endTime: ''
            },
          ],
          floors: [],
          photos: [],
          photoIds: []
        }
      },
      defaultItem: {
        facility: {
          id: '',
          name: '',
          description: '',
          shortDescription: '',
          address: '',
          qrCode: '',
          workDays: [
            {
              workday: 0,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            }, {
              workday: 1,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            }, {
              workday: 2,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            }, {
              workday: 3,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            }, {
              workday: 4,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            }, {
              workday: 5,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            }, {
              workday: 6,
              startTime: '',
              endTime: '',
              isNotWorkingDay: false
            },
          ],
          floors: [],
          photos: [],
          photoIds: []
        }
      },
      timeRegex: /^([01][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: 'id',
      editedPrefix: '',
      sortDesc: true,
      customSort( items, index, isDescending ) {

        if ( index[0] !== undefined ) {
          if ( index[0] === 'createDate' || index[0] === 'updateDate' ) {
            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => new Date( b[index] ) - new Date( a[index] ) )
            } else {
              return items.sort( ( a, b ) => new Date( a[index] ) - new Date( b[index] ) )
            }
          } else if ( index[0] === '' || index[0] === 'id' ) {

            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => b.id - a.id )
            } else {
              return items.sort( ( a, b ) => a.id - b.id )
            }

          } else if ( index[0] && index[0]?.includes( '.' ) ) {
            let i = index[0].split( '.' )[0]

          } else {

            if ( isDescending[0] === true ) {
              return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
            } else if ( isDescending[0] === false ) {
              return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
            }

          }
        } else {

          if ( isDescending[0] === true ) {
            return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
          } else if ( isDescending[0] === false ) {
            return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
          }

        }

        return items
      },
      errorKey: '',
      addEventsDialogErrorKey: '',
      toDeletePhotoIndex: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldFacility: {},
      editedEventItem: {},
      toDeleteEvent: {},
      addressesDataList: [],
      showSpaces: [],
      facilitySpaces: [],
      facilityServices: [],
      eventsList: [],
      eventsNameList: [],
      oldEventsList: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
      emptyReportData: false,
      reportEndDate: '',
      reportStartDate: '',
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },
      maxEndDate: moment().format('DD-MM-YYYY'),
      menu: false,
    }
  },

  computed: {
    maxDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },
    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    headers() {
      return [
        { text: this.facilitiesSnippet.id, value: 'id', width: '0px' },
        { text: this.facilitiesSnippet.name, value: 'name', width: '0px' },
        { text: this.facilitiesSnippet.description, value: 'description', width: '0px' },
        { text: this.facilitiesSnippet.isEventCapable, value: 'isEventCapable', width: '0px' },
        { text: this.facilitiesSnippet.isInvitational, value: 'isInvitational', width: '0px' },
        { text: this.facilitiesSnippet.isBookable, value: 'isBookable', width: '0px' },
        {
          text: this.facilitiesSnippet.maxNumberOfParticipants,
          value: 'maxNumberOfParticipants',
          width: '0px'
        },
        { text: this.facilitiesSnippet.mapPosition, value: 'mapPosition', width: '0px' },
        { text: this.facilitiesSnippet.actions, value: 'actions', width: '0px', sortable: false },
      ]
    },
  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val );
      if ( val === 3 ) {

        setTimeout( () => {
          const workTimeIsValid = this.facilityWorkDaysAreValid( this.editedItem?.facility?.workDays );

          if ( workTimeIsValid === true ) {
            return;
          }
          document.querySelectorAll( '.time-element input' ).forEach( ( item, index ) => {
            setTimeout( () => {
              item.focus();
            }, 10 * index )
            setTimeout( () => {
              item.blur();
            }, 11 * index )
          } )
        }, 0 )
      }
    },


    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },
    dialogDeletePhoto( val ) {
      val || this.closeDelete()
    },

    deleteEventDialog( val ) {
      val || this.closeDeleteEvent()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );
    DataEmitterService.$emit( 'route-change' )
    console.log( this.$route.path )
    if ( this.$route.path.includes( '/facility-details' ) ) {
      return next()
    }
    StorageService.deleteFacilityFromStorage();
    sessionStorage.removeItem( 'model' );
    next()
  },

  async mounted() {
    await this.getFacilityApi();
    await this.getFacilitySpacesApi();
    await this.getFacilityServices();

    this.loading = false;
  },

  methods: {

    openAddNewSpaceDialog( floorId ) {
      this.spaceDialog = true;
      this.currentSpace.floorId = floorId;
    },

    setSpacePhotos( files ) {
      if ( files ) {
        this.currentSpace.photoIds = files;
      }
    },


    closeSpaceDialog() {
      this.spaceDialog = false;
      this.spaceSubmitted = false;
      this.submitted = false;
      this.currentSpace = this.getObjClone( this.defaultSpace );
      this.errorKey = '';
      // this.$refs.spaceFileupload.reset();
    },

    deletePhoto( photoIndex ) {
      event.stopPropagation();
      this.toDeletePhotoIndex = photoIndex;
      this.dialogDeletePhoto = true;
    },

    async deleteFacilityPhoto() {
      let selected = this.editedItem.facility.photos.splice( this.toDeletePhotoIndex, 1 );

      await ApiRequestService.deleteRequest( `api/facility-photos/${ selected[0].id }` );
      this.closeDelete();

      if (this.editedItem.facility.photos.length && !this.editedItem.facility.photos.find(item => item.isMain)) {
        await this.selectAsFacilityMainPhoto(0);
      }
    },

    async deleteFacilitySpace() {
      if ( !this.toDeleteSpace || !this.toDeleteSpace?.id ) {
        return;
      }

      await ApiRequestService.deleteRequest( `api/admin-facility-spaces/${ this.toDeleteSpace.id }` );
      this.facilitySpaces = this.facilitySpaces.filter( item => item.id !== this.toDeleteSpace.id );
      this.closeDelete();
    },

    async selectAsFacilityMainPhoto( photoIndex ) {
      event.stopPropagation();
      if ( photoIndex === null || photoIndex === undefined ) {
        return;
      }

      let selected = this.editedItem.facility.photos.splice( photoIndex, 1 );

      selected[0].isMain = true;
      this.editedItem.facility.photos.unshift( selected[0] );
      await ApiRequestService.postRequest( `api/facility-photos/main/${ this.editedItem.facility.id }`, { photoId: selected[0].id } )
    },

    openSpaceDetails( space ) {
      if ( space && space.id ) {
        let resolvedLocation = this.$router.resolve( {
          name: `space`,
          params: { spaceId: space.id }
        } )?.href;
        StorageService.setFacilityToStorage( this.editedItem );
        console.log( 'resolvedLocation', resolvedLocation )
        this.$router.push( resolvedLocation );
      }
    },

    updateMainTranslations() {
      if ( this.translations['en'] ) {
        this.translations['en'].name = this.editedItem.facility.name;
        this.translations['en'].address = this.editedItem.facility.address;
        this.translations['en'].description = this.editedItem.facility.description;
        this.translations['en'].shortDescription = this.editedItem.facility.shortDescription;
      }
    },

    updateTranslations( data ) {
      this.translations = data;
      this.editedItem.facility = {
        ...this.editedItem.facility,
        ...this.translations['en'],
      }
    },

    setTranslations() {
      this.translations = TranslationUtil.transformToRequestDto(
        this.editedItem.facility.translations,
        {
          address: this.editedItem.facility.address,
          description: this.editedItem.facility.description,
          name: this.editedItem.facility.name,
          shortDescription: this.editedItem.facility.shortDescription,
        }
      );
    },

    async getFacilityApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/admin-facilities/${ this.facilityId }` );
      this.setTranslations();
      this.setFacilityWorkDays();
      StorageService.setFacilityToStorage( this.editedItem );

      this.sortFacilityPhotos();
      this.oldFacility = this.getObjClone( this.editedItem.facility )
    },

    async getFacilitySpacesApi() {
      this.facilitySpaces = await ApiRequestService.getRequest( `api/admin-facility-spaces/facility/${ this.facilityId }` );
      if ( this.facilitySpaces.key === 'Not Found' ) {
        this.facilitySpaces = [];
      }
    },
    async getFacilityServices() {
      this.facilityServices = await ApiRequestService.getRequest( `api/facility-favours/facility/${ this.facilityId }` );
    },

    async getFacilityEventsApi( facilityId ) {
      //TODO: implement API
      this.chips = null//await ApiRequestService.getRequest( `api/facility-services/${ facilityId }` );
      this.oldChips = this.chips;

      if ( this.chips?.errorMessage ) {
        this.chips = [ { id: 0, name: 'TV' }, { id: 1, name: 'Food' } ];
        this.oldChips = this.getObjClone( this.chips )
      }

      this.chipsNameList = this.chips?.map( item => item.name );
    },


    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    async getChangedFacilityData( oldFacility ) {
      const dataFormUpdate = {};

      for ( let [ key, value ] of Object.entries( this.editedItem.facility ) ) {
        if ( oldFacility[key] === this.editedItem.facility[key] ) {
          continue
        }
        if ( key === 'photos' || key === 'floors' ) {
          continue
        }

        if ( key === 'workDays' ) {
          const newDays = [];
          for ( let [ workdayKey, workdayValue ] of Object.entries( this.editedItem.facility[key] ) ) {
            const existsWorkdayIndex = oldFacility[key].findIndex( item => item.workday === workdayValue.workday );

            if ( existsWorkdayIndex !== -1 && ( oldFacility[key][existsWorkdayIndex].startTime !== workdayValue.startTime || oldFacility[key][existsWorkdayIndex].endTime !== workdayValue.endTime ) ) {
              workdayValue.startTime = DateUtil.getUTCTimeFromItalian( workdayValue.startTime?.substr(0,5) );
              workdayValue.endTime = DateUtil.getUTCTimeFromItalian( workdayValue.endTime?.substr(0,5) );
              newDays.push( workdayValue );
            }
          }

          this.editedItem.facility[key] = newDays;
        }

        if ( key === 'translations' ) {
          dataFormUpdate[key] = this.translations;
          continue;
        }

        dataFormUpdate[key] = this.editedItem.facility[key]
      }

      return dataFormUpdate
    },
    async saveSpace() {
      this.spaceSubmitted = true;
      let isValid = this.spaceDataIsValid();
      if ( isValid !== true ) {
        return;
      }
      this.currentSpace.services = this.chipsNameList;
      this.currentSpace.qrCode = this.generateRandomUniqueString( 2 );
      this.currentSpace.facilityId = this.facilityId;
      this.currentSpace.photoIds = await this.uploadSelectedImages();

      let spaceData = await ApiRequestService.postRequest( 'api/admin-facility-spaces', this.currentSpace );
      if ( !spaceData ) {
        return;
      }
      if ( spaceData?.errorMessage ) {
        this.errorKey = spaceData?.errorMessage?.split( ',' );
        return;
      }

      this.facilitySpaces.push( spaceData );
      this.closeSpaceDialog();
    },

    async saveUploadedImages() {
      const dataFormUpdate = {};

      if ( this.editedItem?.facility?.photoIds?.length ) {
        dataFormUpdate['photoIds'] = await this.uploadFacilityImages( this.editedItem.facility );
      }

      const updatedFacility = await ApiRequestService.updateRequest( `api/admin-facilities/${ this.editedItem.facility.id }`, dataFormUpdate )
      if ( updatedFacility.errorMessage ) {
        return
      }

      if ( updatedFacility ) {
        this.editedItem.facility = updatedFacility;
        this.sortFacilityPhotos();
      }

      this.$refs.fileupload.reset();
    },


    showErrorDialog() {
      this.emptyReportData = true;
    },
    closeDialog() {
      this.emptyReportData = false;
    },


    changeDate() {
      if ( this.reportEndDate < this.reportStartDate || new Date(this.reportEndDate) > new Date() ) {
        this.reportEndDate = '';
        this.reportStartDate = '';
        return;
      }

      this.menu = false;
      this.downloadReport( this.reportStartDate, this.reportEndDate ).then(() => {
        this.reportEndDate = '';
        this.reportStartDate = '';
      })
    },

    async downloadReport( startDate, endDate ) {
      const reportData = await ApiRequestService.postRequest( `api/admin-facilities/report/${ this.facilityId }`, {
        startDate,
        endDate
      } );
      if ( !reportData || !reportData.length ) {
        this.showErrorDialog();
        return;
      }

      this.reportHeader = Object.keys( reportData[0] );
      this.reportHeader = this.reportHeader.map( item => item.includes( '_' ) ? item.split( '_' ).join( ' ' ) : item );

      let csvData = [];
      reportData.forEach( data => {
        const rowValue = [];
        for ( let [ key, value ] of Object.entries( data ) ) {
          if ( key === 'Spent_credits' ) {
            rowValue.push( value * -1 );
          } else {
            rowValue.push( value );
          }
        }
        csvData.push( rowValue );
      } );
      const totalData = [];
      let totalAmount = 0;
      this.reportHeader.forEach( _ => {
        totalData.push( ' ' );
      } )
      reportData.forEach( item => {
        totalAmount += parseFloat( item.Spent_credits ) * -1 ?? 0;
      } )

      this.reportHeader.push( 'Total Spent Credits' )
      csvData.unshift( this.reportHeader );

      totalData.push( totalAmount )
      csvData.push( totalData );

      let csvContent = 'data:text/csv;charset=utf-8,';
      csvData.forEach( ( row ) => {
        const csvRow = row.join( ',' );
        csvContent += csvRow + '\r\n';
      } );

      // Create a temporary anchor element to download the CSV file
      const encodedUri = encodeURI( csvContent );
      const link = document.createElement( 'a' );
      link.setAttribute( 'href', encodedUri );
      link.setAttribute( 'download', this.editedItem?.facility?.name + '-report-' + moment().days( -30 ).format( 'MM-DD-YYYY' ) + '-to-' + moment().format( 'MM-DD-YYYY' ) + '.csv' );
      document.body.appendChild( link );

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild( link );
    },

    openCarousel( photoIndex ) {
      let carouselPhotos = this.getObjClone( this.editedItem.facility.photos );
      carouselPhotos = ( carouselPhotos.splice( photoIndex, carouselPhotos.length ) ).concat( carouselPhotos.splice( 0, photoIndex ) );

      if ( carouselPhotos?.length ) {
        this.carouselPhotos = carouselPhotos;
        console.log( 'this.carouselPhotos', this.carouselPhotos )
        this.dialogCarousel = true;
      }
    },

    async uploadSelectedImages() {
      if ( this.currentSpace?.photoIds?.length ) {
        if ( this.currentSpace?.photoIds?.length > 10 ) {
          this.errorKey = 'Maximum number of images allowed 10';
          return;
        }
        return this.uploadFacilitySpaceImages( this.currentSpace );
      }
      return [];
    },

    async updateFacilityRequest( oldFacility ) {
      let existsPhotosCount = this.editedItem?.facility?.photos?.length;
      if ( this.editedItem?.facility?.photoIds?.length + existsPhotosCount > 10 ) {
        this.errorKey = `Maximum number of images allowed 10.You have already ${ existsPhotosCount } images`;
        return;
      }

      const dataFormUpdate = await this.getChangedFacilityData( oldFacility );
      const updatedFacility = await ApiRequestService.updateRequest( `api/admin-facilities/${ this.editedItem.facility.id }`, dataFormUpdate )
      if ( updatedFacility.errorMessage ) {
        return;
      }

      if ( updatedFacility ) {
        this.editedItem.facility = updatedFacility;
        this.setTranslations();
        this.setFacilityWorkDays();
        this.sortFacilityPhotos();
      }
    },

    dataIsValid() {
      const nameIsValid = this.facilityIsValid( this.editedItem?.facility?.name?.trim() );
      const lockIdIsValid = this.lockIdIsValid( this.editedItem?.facility?.lockId );
      const workTimeIsValid = this.facilityWorkDaysAreValid( this.editedItem?.facility?.workDays );
      const isNotValid = [];

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid );
      }
      if ( lockIdIsValid !== true ) {
        isNotValid.push( lockIdIsValid );
      }
      if ( workTimeIsValid !== true ) {
        isNotValid.push( workTimeIsValid );
      }

      if ( isNotValid.length ) {
        return isNotValid;
      }

      return true;
    },

    spaceDataIsValid() {
      const nameIsValid = this.facilityIsValid( this.currentSpace?.name?.trim() );
      const isNotValid = [];

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid );
      }

      /*if (this.currentSpace?.isBookable && this.currentSpace?.isEventCapable) {
        isNotValid.push(this.facilitiesSnippet.cantBeBookableAndEventableAtTheSameTime)
      }*/

      if ( isNotValid.length ) {
        return isNotValid;
      }

      return true;
    },

    facilityIsValid( v ) {
      return ( v && v.length > 0 ) || this.facilitiesSnippet.required;
    },

    lockIdIsValid( v ) {
      return true;
      return !!v || this.facilitiesSnippet.required;
    },

    facilityWorkDaysAreValid( v ) {
      let valid = true;
      v.forEach( item => {
        if ( item.isNotWorkingDay ) {
          return valid;
        }

        if ( !item.startTime ) {
          item.startTime = '10:00';
        }

        if ( !item.endTime ) {
          item.endTime = '20:00';
        }

        if ( !this.timeRegex.test( item.startTime ) || !this.timeRegex.test( item.endTime ) ) {
          valid = 'Wrong time format';
        }

      } );

      if ( valid === true ) {
        this.globalError = true;
      }

      return valid;

    },

    toText( html ) {
      return StringUtil.toText( html );
    },

    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedFacilitiesList : this.paginatedFacilitiesList;
      let list = this.searchField ? this.searchedFacilitiesList : this.facilitiesList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      await this.getFacilityEventsApi( this.editedItem.facility.id );

      this.dialog = true
      this.disableKeyField = true
      this.oldFacility = this.getObjClone( this.editedItem.facility )
    },

    editEvent( item ) {
      event.stopPropagation();
      this.editEventDialog = true;
      this.editedEventItem = this.getObjClone( this.eventsList ).find( event => event?.name === item );
    },

    addEvent() {
      if ( !this.newEventName ) {
        this.addEventsDialogErrorKey = 'Wrong event';
        return;
      }
      if ( this.eventsList?.findIndex( event => event.name.toLowerCase() === this.newEventName.trim().toLowerCase() ) !== -1 ) {
        this.addEventsDialogErrorKey = 'This event exists';
        return;
      }
      this.addEventsDialogErrorKey = '';
      //TODO: send add event api;
      this.eventsList.push( {
        id: this.eventsList?.length,
        name: this.newEventName
      } )
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      this.newEventName = '';
    },

    deleteSelectedEvent( item ) {
      event.stopPropagation();
      this.chips = this.chips.filter( chip => chip.name !== item );
      this.getEditedEventsNames();
    },
    deleteEvent( item ) {
      event.stopPropagation();
      this.toDeleteEvent = item;
      this.deleteEventDialog = true;
    },

    deleteSpaceItem( item ) {
      let toDeleteIndex = this.facilitySpaces.findIndex( fs => fs.id === item.id );
      this.toDeleteSpace = this.getObjClone( this.facilitySpaces[toDeleteIndex] );
      this.dialogDeleteSpace = true
    },

    upgradeItem( item ) {
      this.editedIndex = this.facilitiesList.indexOf( item )
      this.dialogUpgrade = true
    },


    async deleteEventConfirm() {
      this.eventsList = this.eventsList?.filter( chip => chip.name !== this.toDeleteEvent );
      this.oldEventsList = this.getObjClone( this.eventsList );
      this.getEditedEventsNames();
      //TODO: send delete event request

      this.closeDeleteEvent()
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteSpace = false;
      this.dialogDeletePhoto = false;
      this.submitted = false;
    },

    closeDialogCarousel() {
      this.dialogCarousel = false;
      this.carouselPhotos = {}
    },

    closeDeleteEvent() {
      this.deleteEventDialog = false;
    },

    closeUpgrade() {
      this.dialogUpgrade = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    generateRandomUniqueString( len ) {
      let text = '';
      for ( let i = 0; i < len; i++ ) {
        text += Math.random().toString( 36 ).slice( 2 ) + Date.now()
      }

      return text
    },


    async uploadFacilityImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/facility-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },
    async uploadFacilitySpaceImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/facility-photos/facility-space-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },

    async updateFacility() {

      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        this.globalError = isValid;
        console.log( 'this.globalError', this.globalError )
        return;
      }

      await this.updateFacilityRequest( this.oldFacility );
    },

    async save() {
      this.submitted = true;
      await this.updateFacility();
    },

    getEditedEventsNames() {
      this.chipsNameList = this.chips?.map( item => item.name );
      this.eventsNameList = this.eventsList?.map( item => item.name );
    },

    saveEditedEventItem() {
      let editedEventItemIndex = this.eventsList?.findIndex( item => item.id === this.editedEventItem.id );
      this.$set( this.eventsList, editedEventItemIndex, this.editedEventItem );

      this.chipsNameList.forEach( item => {
        let selectedEditedEventItemIndex = this.chips.findIndex( chip => chip.name === item );

        let editedEventItemIndex = this.oldEventsList.findIndex( chip => chip.name === item );
        let editedEventItem = this.eventsList[editedEventItemIndex];

        if ( selectedEditedEventItemIndex !== -1 ) {
          this.$set( this.chips, selectedEditedEventItemIndex, {
            id: editedEventItem.id,
            name: editedEventItem.name
          } );
        } else {
          if ( editedEventItem ) {
            this.chips.push( {
              id: editedEventItem.id,
              name: editedEventItem.name,
            } )
          }
        }
      } )

      this.getEditedEventsNames();

      this.eventsListHasBeenEdited = true;
      this.closeEditEventDialog();
    },

    getDayName( workDay ) {
      if ( !workDay ) {
        return
      }

      switch ( workDay.workday ) {
        case 0:
          return 'Monday'
        case 1:
          return 'Tuesday'
        case 2:
          return 'Wednesday'
        case 3:
          return 'Thursday'
        case 4:
          return 'Friday'
        case 5:
          return 'Saturday'
        case 6:
          return 'Sunday'
      }
    },

    saveEvents() {
      this.oldChips = this.getObjClone( this.chips );
      if ( this.eventsListHasBeenEdited ) {
        //TODO: send events list update request
      }
      //TODO: end current facility events list update request
      this.closeEventDialog();
    },


    setPhotos( files ) {
      if ( files && files.length ) {
        if ( files.length + this.editedItem.facility.photos.length > 10 ) {
          this.errorKey = 'Is not able to upload more then 10 images';
          this.$refs.fileupload.reset();
        } else {
          this.errorKey = '';
          for ( let file of files ) {
            if ( file.size / 1024 / 1024 > 3 ) {
              this.errorKey = 'Image size should be less then 3mb.';
              this.$refs.fileupload.reset();
              return
            }
          }
          this.editedItem.facility.photoIds = files;
        }
      }
    },

    sortFacilityPhotos() {
      this.editedItem.facility.photos.sort( ( a, b ) => ( +b.isMain ) - ( +a.isMain ) )
    },

    setFacilityWorkDays() {
      const apiWorkDays = this.getObjClone( this.editedItem.facility.workDays );
      this.editedItem.facility.workDays = this.getObjClone( this.defaultItem.facility.workDays );

      for ( let i = 0; i < this.editedItem.facility.workDays.length; i++ ) {
        let apiWorkDayIndex = apiWorkDays.findIndex( item => item.workday === this.editedItem.facility.workDays[i].workday );

        if ( apiWorkDayIndex !== -1 ) {

          this.editedItem.facility.workDays[i] = apiWorkDays[apiWorkDayIndex];
          this.editedItem.facility.workDays[i].isNotWorkingDay = !( apiWorkDays[apiWorkDayIndex].startTime && apiWorkDays[apiWorkDayIndex].endTime ) || !( apiWorkDays[apiWorkDayIndex].startTime !== apiWorkDays[apiWorkDayIndex].endTime )

          this.workDays[i] = Object.assign( this.editedItem.facility.workDays[i] );
          this.workDays[i].startTime = DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + this.workDays[i].startTime?.substr(0, 5) );
          this.workDays[i].endTime = DateUtil.getItalianTimeFromUTC(moment().format( 'YYYY-MM-DD' ) + ' ' + this.workDays[i].endTime?.substr(0, 5) );
        }
      }

    },

    getToLocaleTime( time ) {
      const hour = time.split( ':' )[0];
      const minutes = time.split( ':' )[1];
      return DateUtil.getToLocaleTimeWithoutSecond( new Date().setHours( hour, minutes ) )
    },

    changeDayIsWorking( index, $event ) {
      let currentContainer = document.querySelector( `.f_working-dy-index-${ index }` );

      let startTime = $event ? '00:00' : this.oldFacility.workDays[index].startTime;
      let endTime = $event ? '00:00' : this.oldFacility.workDays[index].endTime;
      let startTimeElement = currentContainer.querySelector( '.f_start-time' );
      let endTimeElement = currentContainer.querySelector( '.f_end-time' );

      startTimeElement.classList.remove( "disabled" );
      endTimeElement.classList.remove( "disabled" );
      startTimeElement.querySelector( 'input' ).removeAttribute( "disabled" );
      endTimeElement.querySelector( 'input' ).removeAttribute( "disabled" );

      if ( $event ) {
        startTimeElement.classList.add( "disabled" );
        endTimeElement.classList.add( "disabled" );
        startTimeElement.querySelector( 'input' ).setAttribute( "disabled", $event );
        endTimeElement.querySelector( 'input' ).setAttribute( "disabled", $event );
      }

      startTimeElement.querySelector( 'input' ).value = startTime;
      endTimeElement.querySelector( 'input' ).value = endTime;


      this.editedItem.facility.workDays[index].isNotWorkingDay = $event;
      this.editedItem.facility.workDays[index].startTime = startTime;
      this.editedItem.facility.workDays[index].endTime = endTime;
    },
  }
}

